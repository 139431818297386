const resource = 'userrole'

export default $axios => ({
  add(userId, roleName, residenceId) {
    const payload = {
      RoleName: roleName,
      UserId: userId,
      ResidenceId: residenceId
    }
    return $axios.$post(`/${resource}`, payload)
  },
  update(userId, roleName, residenceId) {
    const payload = {
      RoleName: roleName,
      UserId: userId,
      ResidenceId: residenceId
    }
    return $axios.$patch(`/${resource}`, payload)
  },
  delete(userId, roleName, residenceId) {
    const payload = {
      RoleName: roleName,
      UserId: userId,
      ResidenceId: residenceId
    }

    return $axios({
      url: `/${resource}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: payload
    })
  },
  getUserRoles(userid) {
    return $axios.$get(`/${resource}?$filter=userId eq ${userid}`)
  },
  getUserWithRole(roleName) {
    return $axios.$get(`/${resource}?$filter=roleName eq '${roleName}'`)
  }
})
