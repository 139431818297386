const state = () => ({
  saveNote: {
    description: null,
    residentId: null,
    parentNoteId: null,
    goalId: null,
    needsAttention: false,
    specifiedDateTime: null
  }
})

export default state
