const resource = 'archive'

function convertDateToString(date) {
  return date.toISOString().slice(0, 11) + '00:00:00Z'
}

function convertDateStringsToQueryFilterPart(from, until) {
  from = new Date(from)
  until = new Date(until)
  until.setDate(until.getDate() + 1)

  from = convertDateToString(from)
  until = convertDateToString(until)
  return `DeletedAt ge ${from} AND DeletedAt le ${until}`
}

export default $axios => ({
  getAll() {
    return $axios.$get(`/${resource}`)
  },
  getAllByDate(from, until, searchText = null) {
    if (!from || !until) {
      return this.getAll()
    }

    if (searchText) {
      return this.search(from, until, searchText)
    }

    const dateQuery = convertDateStringsToQueryFilterPart(from, until)

    return $axios.$get(
      `/${resource}?$filter=${dateQuery}&orderby=DeletedAt desc`
    )
  },
  getFromResidentByDate(residentId, from, until) {
    const dateQuery = convertDateStringsToQueryFilterPart(from, until)
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} AND ${dateQuery}&orderby=DeletedAt desc`
    )
  },
  getFromResidenceByDate(residenceId, from, until) {
    const dateQuery = convertDateStringsToQueryFilterPart(from, until)
    return $axios.$get(
      `/${resource}?$filter=residenceId eq ${residenceId} AND ${dateQuery}&orderby=DeletedAt desc`
    )
  },
  search(from, until, searchText) {
    const dateQuery = convertDateStringsToQueryFilterPart(from, until)

    const searchQuery = `contains(tolower(name), '${searchText}')
or contains(properties/description, '${searchText}')
or contains(properties/what, '${searchText}')
or contains(properties/when, '${searchText}')
or contains(properties/how, '${searchText}')`

    return $axios.$get(
      `/${resource}?$filter=${dateQuery} and (${searchQuery})&orderby=DeletedAt desc`
    )
  },
  reset(typeName, id) {
    return $axios.patch(`/${resource}/${typeName}/${id}`)
  }
})
