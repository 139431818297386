const resource = 'residentnote'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  allFilteredByResident(residentId) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and parentNoteId eq null and goalId eq null&$orderby=specifiedDateTime desc`
    )
  },
  allFilteredByResidentWithPage(residentId, page, nrOfItemsPerPage) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and parentNoteId eq null and goalId eq null&$skip=${page*nrOfItemsPerPage}&$top=${nrOfItemsPerPage}&$orderby=specifiedDateTime desc`
    )
  },
  allFilteredByResidentWithSearch(
    residentId,
    page,
    nrOfItemsPerPage,
    searchWord
  ) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and parentNoteId eq null and goalId eq null and contains(description, '${searchWord}')&$skip=${page*nrOfItemsPerPage}&$top=${nrOfItemsPerPage}&$orderby=specifiedDateTime desc`
    )
  },
  allFilteredByResidentWithPageAndTimeInterval(
    residentId,
    page,
    nrOfItemsPerPage,
    searchWord,
    dateStart,
    dateEnd
  ) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and parentNoteId eq null and goalId eq null and contains(description, '${searchWord}') and specifiedDateTime ge ${dateStart} and specifiedDateTime le ${dateEnd} &$skip=${page*nrOfItemsPerPage}&$top=${nrOfItemsPerPage}&$orderby=specifiedDateTime desc`
    )
  },
  allNotes() {
    return $axios.$get(
      `/${resource}?$filter=goalId eq null and parentNoteId eq null&$orderby=specifiedDateTime desc`
    )
  },
  notesBetweenDatesByResident(residentId, start, end) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and specifiedDateTime ge ${start} and specifiedDateTime le ${end} &$orderby=specifiedDateTime desc`
    )
  },
  allNotesThatNeedAttention(today, tomorrow) {
    return $axios.$get(
      `/${resource}?$filter=goalId eq null and parentNoteId eq null and needsAttention eq true and specifiedDateTime ge ${today} and  specifiedDateTime le ${tomorrow} &$orderby=specifiedDateTime desc`
    )
  },
  allComments(parentNoteId) {
    return $axios.$get(`/${resource}?$filter=parentNoteId eq ${parentNoteId}`)
  },
  allCommentsForGoal(parentNoteId) {
    return $axios.$get(
      `/${resource}?$filter=goalId eq ${parentNoteId}&$orderby=createdOnDateTimeOffsetUtc desc`
    )
  },
  allCommentsOfAllResidentNotes(residentId) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and parentNoteId ne null`
    )
  },
  async show(noteId, onlyFirst = false) {
    let request = $axios.$get(`/${resource}?$filter=id eq ${noteId}`)
    if(!onlyFirst){
      return request;
    }
    let result = await request.catch((err) => {
      this.$notifyError(err)
      return null;
    });
    if(result && Array.isArray(result)){
      return result[0];
    }
    return null;
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  },
  totalNotesPerResident(residentId) {
    return $axios.get(`/${resource}/${residentId}/count`)
  },
  allFilteredForDashboardResident(residentId, yesterday, today) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId} and parentNoteId eq null and goalId eq null and specifiedDateTime ge ${yesterday} and  specifiedDateTime le ${today}&$orderby=createdOnDateTimeOffsetUtc desc`
    )
  }
})
