
export default {
  name: 'InitialsProfilePicture',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    initials: {
      type: String,
      default: ''
    },
    mini: Boolean
  },
  data() {
    return {
      cardColors: ['#88A2AA', '#ADA296', '#E2856E', '#F42C04', '#0F1A20']
    }
  },
  computed: {
    getColor() {
      return this.cardColors[Math.floor(Math.random() * this.cardColors.length)]
    }
  }
}
