import { render, staticRenderFns } from "./DashboardNavbar.vue?vue&type=template&id=8ec28176&"
import script from "./DashboardNavbar.vue?vue&type=script&lang=js&"
export * from "./DashboardNavbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InitialsProfilePicture: require('/src/components/custom/UI/InitialsProfilePicture.vue').default,BaseDropdown: require('/src/components/argon-core/BaseDropdown.vue').default,BaseNav: require('/src/components/argon-core/Navbar/BaseNav.vue').default})
