const resource = 'plan'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  allByResident(residentId) {
    return $axios.$get(`/${resource}?$filter=residentId eq ${residentId}&orderby=date desc`)
  },
  allById(planId) {
    return $axios.$get(`/${resource}?$filter=id eq ${planId}`)
  },
  allQuestions() {
    return $axios.$get(`/${resource}/questions`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  },
  duplicate(id) {
    return $axios.$post(`/${resource}/${id}/duplicate`)
  }
})
