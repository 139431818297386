
export default {
  name: 'BaseCheckbox',
  model: {
    prop: 'checked'
  },
  props: {
    checked: {
      type: [Array, Boolean],
      description: 'Whether checkbox is checked'
    },
    disabled: {
      type: Boolean,
      description: 'Whether checkbox is disabled'
    },
    ariaLabel: String,
    inline: {
      type: Boolean,
      description: 'Whether checkbox is inline'
    },
    inputClasses: {
      type: [String, Object, Array],
      description: 'Checkbox input classes'
    },
    type: {
      type: String,
      description: 'Checkbox type (e.g info, danger etc)'
    }
  },
  data() {
    return {
      cbId: '',
      touched: false
    }
  },
  computed: {
    model: {
      get() {
        return this.checked
      },
      set(check) {
        if (!this.touched) {
          this.touched = true
        }
        this.$emit('input', check)
      }
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`
      }
    }
  },
  mounted() {
    this.cbId = Math.random()
      .toString(16)
      .slice(2)
  }
}
