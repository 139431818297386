const getDefaultState = () => ({
  showSupportModal: false
})

export const state = () => getDefaultState()

export const mutations = {
  TOGGLE_MODAL(state, bool) {
    state.showSupportModal = bool
  },
  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}
export const actions = {
  toggleSupportModal({ commit }, bool) {
    commit('TOGGLE_MODAL', bool)
  }
}

export const getters = {}
