import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _49af5aba = () => interopDefault(import('../pages/activate.vue' /* webpackChunkName: "pages/activate" */))
const _641c445d = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _b87395de = () => interopDefault(import('../pages/locked.vue' /* webpackChunkName: "pages/locked" */))
const _43bdd19c = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _55362d9c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _2f164235 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _353e4e04 = () => interopDefault(import('../pages/profile/contracts.vue' /* webpackChunkName: "pages/profile/contracts" */))
const _70b82cee = () => interopDefault(import('../pages/profile/dossier.vue' /* webpackChunkName: "pages/profile/dossier" */))
const _ad631b78 = () => interopDefault(import('../pages/profile/incidents.vue' /* webpackChunkName: "pages/profile/incidents" */))
const _c3b59472 = () => interopDefault(import('../pages/profile/incidents/index.vue' /* webpackChunkName: "pages/profile/incidents/index" */))
const _deef8d54 = () => interopDefault(import('../pages/profile/incidents/add.vue' /* webpackChunkName: "pages/profile/incidents/add" */))
const _1567c526 = () => interopDefault(import('../pages/profile/incidents/_incident.vue' /* webpackChunkName: "pages/profile/incidents/_incident" */))
const _6996c2f7 = () => interopDefault(import('../pages/reporting.vue' /* webpackChunkName: "pages/reporting" */))
const _07cf8292 = () => interopDefault(import('../pages/residences/index.vue' /* webpackChunkName: "pages/residences/index" */))
const _4bf21cef = () => interopDefault(import('../pages/residents/index.vue' /* webpackChunkName: "pages/residents/index" */))
const _baffca28 = () => interopDefault(import('../pages/staff/index.vue' /* webpackChunkName: "pages/staff/index" */))
const _5d9d171f = () => interopDefault(import('../pages/activation/failed.vue' /* webpackChunkName: "pages/activation/failed" */))
const _c1f97c08 = () => interopDefault(import('../pages/activation/successful.vue' /* webpackChunkName: "pages/activation/successful" */))
const _021f92e4 = () => interopDefault(import('../pages/admin/archive/index.vue' /* webpackChunkName: "pages/admin/archive/index" */))
const _f62bf114 = () => interopDefault(import('../pages/admin/health-insurances/index.vue' /* webpackChunkName: "pages/admin/health-insurances/index" */))
const _49c9e64c = () => interopDefault(import('../pages/admin/healthcare-specialists/index.vue' /* webpackChunkName: "pages/admin/healthcare-specialists/index" */))
const _6fca88ad = () => interopDefault(import('../pages/admin/incidents/index.vue' /* webpackChunkName: "pages/admin/incidents/index" */))
const _27e6437c = () => interopDefault(import('../pages/admin/representatives/index.vue' /* webpackChunkName: "pages/admin/representatives/index" */))
const _f3c2f392 = () => interopDefault(import('../pages/admin/residences/index.vue' /* webpackChunkName: "pages/admin/residences/index" */))
const _a63101ee = () => interopDefault(import('../pages/admin/roles/index.vue' /* webpackChunkName: "pages/admin/roles/index" */))
const _aee9b928 = () => interopDefault(import('../pages/admin/staff/index.vue' /* webpackChunkName: "pages/admin/staff/index" */))
const _0dd7a99a = () => interopDefault(import('../pages/admin/volunteers/index.vue' /* webpackChunkName: "pages/admin/volunteers/index" */))
const _3734ca7e = () => interopDefault(import('../pages/residents/add.vue' /* webpackChunkName: "pages/residents/add" */))
const _0063aac5 = () => interopDefault(import('../pages/admin/health-insurances/add.vue' /* webpackChunkName: "pages/admin/health-insurances/add" */))
const _6439471b = () => interopDefault(import('../pages/admin/healthcare-specialists/add.vue' /* webpackChunkName: "pages/admin/healthcare-specialists/add" */))
const _2fa5c491 = () => interopDefault(import('../pages/admin/representatives/add.vue' /* webpackChunkName: "pages/admin/representatives/add" */))
const _2a7ae5c6 = () => interopDefault(import('../pages/admin/residences/add.vue' /* webpackChunkName: "pages/admin/residences/add" */))
const _7a6feb18 = () => interopDefault(import('../pages/admin/roles/add.vue' /* webpackChunkName: "pages/admin/roles/add" */))
const _d331678a = () => interopDefault(import('../pages/admin/staff/add.vue' /* webpackChunkName: "pages/admin/staff/add" */))
const _f164cc7c = () => interopDefault(import('../pages/admin/volunteers/add.vue' /* webpackChunkName: "pages/admin/volunteers/add" */))
const _4d9c27c1 = () => interopDefault(import('../pages/admin/health-insurances/edit/_id.vue' /* webpackChunkName: "pages/admin/health-insurances/edit/_id" */))
const _64ae5daa = () => interopDefault(import('../pages/admin/healthcare-specialists/edit/_id.vue' /* webpackChunkName: "pages/admin/healthcare-specialists/edit/_id" */))
const _5cde4d75 = () => interopDefault(import('../pages/admin/representatives/edit/_id.vue' /* webpackChunkName: "pages/admin/representatives/edit/_id" */))
const _c1769a5e = () => interopDefault(import('../pages/admin/residences/edit/_residence.vue' /* webpackChunkName: "pages/admin/residences/edit/_residence" */))
const _ff068654 = () => interopDefault(import('../pages/admin/residences/view/_residence.vue' /* webpackChunkName: "pages/admin/residences/view/_residence" */))
const _024bb964 = () => interopDefault(import('../pages/admin/roles/edit/_id.vue' /* webpackChunkName: "pages/admin/roles/edit/_id" */))
const _fd1de238 = () => interopDefault(import('../pages/admin/volunteers/edit/_id.vue' /* webpackChunkName: "pages/admin/volunteers/edit/_id" */))
const _e976fa42 = () => interopDefault(import('../pages/residences/_id.vue' /* webpackChunkName: "pages/residences/_id" */))
const _06740a5a = () => interopDefault(import('../pages/residences/_id/notes/_note.vue' /* webpackChunkName: "pages/residences/_id/notes/_note" */))
const _541ee1d2 = () => interopDefault(import('../pages/residents/_id.vue' /* webpackChunkName: "pages/residents/_id" */))
const _1185285a = () => interopDefault(import('../pages/residents/_id/index.vue' /* webpackChunkName: "pages/residents/_id/index" */))
const _2138545e = () => interopDefault(import('../pages/residents/_id/agreements.vue' /* webpackChunkName: "pages/residents/_id/agreements" */))
const _6c32fb94 = () => interopDefault(import('../pages/residents/_id/agreements/index.vue' /* webpackChunkName: "pages/residents/_id/agreements/index" */))
const _3ef70e63 = () => interopDefault(import('../pages/residents/_id/agreements/add.vue' /* webpackChunkName: "pages/residents/_id/agreements/add" */))
const _4d51722d = () => interopDefault(import('../pages/residents/_id/agreements/edit/_agreementId.vue' /* webpackChunkName: "pages/residents/_id/agreements/edit/_agreementId" */))
const _7ea1e21c = () => interopDefault(import('../pages/residents/_id/agreements/view/_agreementId.vue' /* webpackChunkName: "pages/residents/_id/agreements/view/_agreementId" */))
const _321459ce = () => interopDefault(import('../pages/residents/_id/contact-persons.vue' /* webpackChunkName: "pages/residents/_id/contact-persons" */))
const _44048b80 = () => interopDefault(import('../pages/residents/_id/days-out.vue' /* webpackChunkName: "pages/residents/_id/days-out" */))
const _8bcb1bfa = () => interopDefault(import('../pages/residents/_id/days-out/index.vue' /* webpackChunkName: "pages/residents/_id/days-out/index" */))
const _43d75492 = () => interopDefault(import('../pages/residents/_id/days-out/add.vue' /* webpackChunkName: "pages/residents/_id/days-out/add" */))
const _2f8df989 = () => interopDefault(import('../pages/residents/_id/days-out/edit/_daysOutId.vue' /* webpackChunkName: "pages/residents/_id/days-out/edit/_daysOutId" */))
const _73061dd3 = () => interopDefault(import('../pages/residents/_id/dossier.vue' /* webpackChunkName: "pages/residents/_id/dossier" */))
const _58cbba16 = () => interopDefault(import('../pages/residents/_id/dossier/index.vue' /* webpackChunkName: "pages/residents/_id/dossier/index" */))
const _18518565 = () => interopDefault(import('../pages/residents/_id/dossier/add.vue' /* webpackChunkName: "pages/residents/_id/dossier/add" */))
const _84538770 = () => interopDefault(import('../pages/residents/_id/goals.vue' /* webpackChunkName: "pages/residents/_id/goals" */))
const _6fc66ccb = () => interopDefault(import('../pages/residents/_id/goals/index.vue' /* webpackChunkName: "pages/residents/_id/goals/index" */))
const _0c59ebeb = () => interopDefault(import('../pages/residents/_id/goals/_goal.vue' /* webpackChunkName: "pages/residents/_id/goals/_goal" */))
const _4cdc62d1 = () => interopDefault(import('../pages/residents/_id/health-insurance.vue' /* webpackChunkName: "pages/residents/_id/health-insurance" */))
const _c3646ed8 = () => interopDefault(import('../pages/residents/_id/health-insurance/index.vue' /* webpackChunkName: "pages/residents/_id/health-insurance/index" */))
const _393dc93a = () => interopDefault(import('../pages/residents/_id/health-insurance/add.vue' /* webpackChunkName: "pages/residents/_id/health-insurance/add" */))
const _65092195 = () => interopDefault(import('../pages/residents/_id/health-insurance/edit/_contractId.vue' /* webpackChunkName: "pages/residents/_id/health-insurance/edit/_contractId" */))
const _6a75f4aa = () => interopDefault(import('../pages/residents/_id/healthcare-specialists.vue' /* webpackChunkName: "pages/residents/_id/healthcare-specialists" */))
const _b84258a6 = () => interopDefault(import('../pages/residents/_id/healthcare-specialists/index.vue' /* webpackChunkName: "pages/residents/_id/healthcare-specialists/index" */))
const _d887ce88 = () => interopDefault(import('../pages/residents/_id/healthcare-specialists/add.vue' /* webpackChunkName: "pages/residents/_id/healthcare-specialists/add" */))
const _4fe3bde9 = () => interopDefault(import('../pages/residents/_id/incidents.vue' /* webpackChunkName: "pages/residents/_id/incidents" */))
const _668b1ca8 = () => interopDefault(import('../pages/residents/_id/incidents/index.vue' /* webpackChunkName: "pages/residents/_id/incidents/index" */))
const _0e952a7b = () => interopDefault(import('../pages/residents/_id/incidents/add.vue' /* webpackChunkName: "pages/residents/_id/incidents/add" */))
const _3d82868b = () => interopDefault(import('../pages/residents/_id/incidents/_incident.vue' /* webpackChunkName: "pages/residents/_id/incidents/_incident" */))
const _30ebfa2c = () => interopDefault(import('../pages/residents/_id/legal-representatives.vue' /* webpackChunkName: "pages/residents/_id/legal-representatives" */))
const _8fca7142 = () => interopDefault(import('../pages/residents/_id/measurements.vue' /* webpackChunkName: "pages/residents/_id/measurements" */))
const _69e60aa2 = () => interopDefault(import('../pages/residents/_id/measurements/index.vue' /* webpackChunkName: "pages/residents/_id/measurements/index" */))
const _089e88f1 = () => interopDefault(import('../pages/residents/_id/measurements/add.vue' /* webpackChunkName: "pages/residents/_id/measurements/add" */))
const _b4930608 = () => interopDefault(import('../pages/residents/_id/measurements/edit/_measurementTemplateInstanceId.vue' /* webpackChunkName: "pages/residents/_id/measurements/edit/_measurementTemplateInstanceId" */))
const _18fb5afe = () => interopDefault(import('../pages/residents/_id/measurements/view/_measurementTemplateInstanceId.vue' /* webpackChunkName: "pages/residents/_id/measurements/view/_measurementTemplateInstanceId" */))
const _fd729be8 = () => interopDefault(import('../pages/residents/_id/medications.vue' /* webpackChunkName: "pages/residents/_id/medications" */))
const _cedf00e2 = () => interopDefault(import('../pages/residents/_id/medications/index.vue' /* webpackChunkName: "pages/residents/_id/medications/index" */))
const _2f4b551e = () => interopDefault(import('../pages/residents/_id/medications/add.vue' /* webpackChunkName: "pages/residents/_id/medications/add" */))
const _16653312 = () => interopDefault(import('../pages/residents/_id/medications/edit/_medicationId.vue' /* webpackChunkName: "pages/residents/_id/medications/edit/_medicationId" */))
const _a64c826e = () => interopDefault(import('../pages/residents/_id/notes.vue' /* webpackChunkName: "pages/residents/_id/notes" */))
const _62a44a8c = () => interopDefault(import('../pages/residents/_id/notes/index.vue' /* webpackChunkName: "pages/residents/_id/notes/index" */))
const _132d19ea = () => interopDefault(import('../pages/residents/_id/notes/_note.vue' /* webpackChunkName: "pages/residents/_id/notes/_note" */))
const _56f2055e = () => interopDefault(import('../pages/residents/_id/plan.vue' /* webpackChunkName: "pages/residents/_id/plan" */))
const _313a8314 = () => interopDefault(import('../pages/residents/_id/plan/index.vue' /* webpackChunkName: "pages/residents/_id/plan/index" */))
const _073a75e3 = () => interopDefault(import('../pages/residents/_id/plan/add.vue' /* webpackChunkName: "pages/residents/_id/plan/add" */))
const _f2678828 = () => interopDefault(import('../pages/residents/_id/plan/edit/_planId.vue' /* webpackChunkName: "pages/residents/_id/plan/edit/_planId" */))
const _f2520ff2 = () => interopDefault(import('../pages/residents/_id/plan/view/_planId.vue' /* webpackChunkName: "pages/residents/_id/plan/view/_planId" */))
const _1e7867fe = () => interopDefault(import('../pages/residents/_id/representatives/index.vue' /* webpackChunkName: "pages/residents/_id/representatives/index" */))
const _1a8a2fa2 = () => interopDefault(import('../pages/residents/_id/risk-analysis.vue' /* webpackChunkName: "pages/residents/_id/risk-analysis" */))
const _5d68f4a5 = () => interopDefault(import('../pages/residents/_id/risk-analysis/index.vue' /* webpackChunkName: "pages/residents/_id/risk-analysis/index" */))
const _58ca17b4 = () => interopDefault(import('../pages/residents/_id/risk-analysis/add.vue' /* webpackChunkName: "pages/residents/_id/risk-analysis/add" */))
const _1530c746 = () => interopDefault(import('../pages/residents/_id/risk-analysis/edit/_riskAnalysisId.vue' /* webpackChunkName: "pages/residents/_id/risk-analysis/edit/_riskAnalysisId" */))
const _302e0d78 = () => interopDefault(import('../pages/residents/_id/risk-analysis/view/_riskAnalysisId.vue' /* webpackChunkName: "pages/residents/_id/risk-analysis/view/_riskAnalysisId" */))
const _14854ab2 = () => interopDefault(import('../pages/residents/_id/sports.vue' /* webpackChunkName: "pages/residents/_id/sports" */))
const _7acf07ea = () => interopDefault(import('../pages/residents/_id/sports/index.vue' /* webpackChunkName: "pages/residents/_id/sports/index" */))
const _7e938f8e = () => interopDefault(import('../pages/residents/_id/sports/add.vue' /* webpackChunkName: "pages/residents/_id/sports/add" */))
const _05488e6d = () => interopDefault(import('../pages/residents/_id/sports/edit/_sportId.vue' /* webpackChunkName: "pages/residents/_id/sports/edit/_sportId" */))
const _0ed76712 = () => interopDefault(import('../pages/residents/_id/volunteer.vue' /* webpackChunkName: "pages/residents/_id/volunteer" */))
const _812a4fd6 = () => interopDefault(import('../pages/residents/_id/volunteer/index.vue' /* webpackChunkName: "pages/residents/_id/volunteer/index" */))
const _6bce1724 = () => interopDefault(import('../pages/residents/_id/volunteer/add.vue' /* webpackChunkName: "pages/residents/_id/volunteer/add" */))
const _506b1610 = () => interopDefault(import('../pages/residents/_id/representatives/add.vue' /* webpackChunkName: "pages/residents/_id/representatives/add" */))
const _1142b026 = () => interopDefault(import('../pages/residents/_id/representatives/_representativeId.vue' /* webpackChunkName: "pages/residents/_id/representatives/_representativeId" */))
const _6eefe854 = () => interopDefault(import('../pages/staff/_id.vue' /* webpackChunkName: "pages/staff/_id" */))
const _54b9dfd7 = () => interopDefault(import('../pages/staff/_id/index.vue' /* webpackChunkName: "pages/staff/_id/index" */))
const _7eb64aa6 = () => interopDefault(import('../pages/staff/_id/contracts.vue' /* webpackChunkName: "pages/staff/_id/contracts" */))
const _0c6dbca9 = () => interopDefault(import('../pages/staff/_id/contracts/index.vue' /* webpackChunkName: "pages/staff/_id/contracts/index" */))
const _43a910b8 = () => interopDefault(import('../pages/staff/_id/contracts/add.vue' /* webpackChunkName: "pages/staff/_id/contracts/add" */))
const _48183f03 = () => interopDefault(import('../pages/staff/_id/contracts/_contractId.vue' /* webpackChunkName: "pages/staff/_id/contracts/_contractId" */))
const _882a2be0 = () => interopDefault(import('../pages/staff/_id/dossier.vue' /* webpackChunkName: "pages/staff/_id/dossier" */))
const _78c3a493 = () => interopDefault(import('../pages/staff/_id/dossier/index.vue' /* webpackChunkName: "pages/staff/_id/dossier/index" */))
const _e56829bc = () => interopDefault(import('../pages/staff/_id/dossier/add.vue' /* webpackChunkName: "pages/staff/_id/dossier/add" */))
const _1a732234 = () => interopDefault(import('../pages/staff/_id/incidents.vue' /* webpackChunkName: "pages/staff/_id/incidents" */))
const _633c9e2e = () => interopDefault(import('../pages/staff/_id/incidents/index.vue' /* webpackChunkName: "pages/staff/_id/incidents/index" */))
const _2e8d14f8 = () => interopDefault(import('../pages/staff/_id/incidents/add.vue' /* webpackChunkName: "pages/staff/_id/incidents/add" */))
const _e2644170 = () => interopDefault(import('../pages/staff/_id/incidents/_incident.vue' /* webpackChunkName: "pages/staff/_id/incidents/_incident" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/activate",
    component: _49af5aba,
    name: "activate"
  }, {
    path: "/dashboard",
    component: _641c445d,
    name: "dashboard"
  }, {
    path: "/locked",
    component: _b87395de,
    name: "locked"
  }, {
    path: "/login",
    component: _43bdd19c,
    name: "login"
  }, {
    path: "/profile",
    component: _55362d9c,
    children: [{
      path: "",
      component: _2f164235,
      name: "profile"
    }, {
      path: "contracts",
      component: _353e4e04,
      name: "profile-contracts"
    }, {
      path: "dossier",
      component: _70b82cee,
      name: "profile-dossier"
    }, {
      path: "incidents",
      component: _ad631b78,
      children: [{
        path: "",
        component: _c3b59472,
        name: "profile-incidents"
      }, {
        path: "add",
        component: _deef8d54,
        name: "profile-incidents-add"
      }, {
        path: ":incident",
        component: _1567c526,
        name: "profile-incidents-incident"
      }]
    }]
  }, {
    path: "/reporting",
    component: _6996c2f7,
    name: "reporting"
  }, {
    path: "/residences",
    component: _07cf8292,
    name: "residences"
  }, {
    path: "/residents",
    component: _4bf21cef,
    name: "residents"
  }, {
    path: "/staff",
    component: _baffca28,
    name: "staff"
  }, {
    path: "/activation/failed",
    component: _5d9d171f,
    name: "activation-failed"
  }, {
    path: "/activation/successful",
    component: _c1f97c08,
    name: "activation-successful"
  }, {
    path: "/admin/archive",
    component: _021f92e4,
    name: "admin-archive"
  }, {
    path: "/admin/health-insurances",
    component: _f62bf114,
    name: "admin-health-insurances"
  }, {
    path: "/admin/healthcare-specialists",
    component: _49c9e64c,
    name: "admin-healthcare-specialists"
  }, {
    path: "/admin/incidents",
    component: _6fca88ad,
    name: "admin-incidents"
  }, {
    path: "/admin/representatives",
    component: _27e6437c,
    name: "admin-representatives"
  }, {
    path: "/admin/residences",
    component: _f3c2f392,
    name: "admin-residences"
  }, {
    path: "/admin/roles",
    component: _a63101ee,
    name: "admin-roles"
  }, {
    path: "/admin/staff",
    component: _aee9b928,
    name: "admin-staff"
  }, {
    path: "/admin/volunteers",
    component: _0dd7a99a,
    name: "admin-volunteers"
  }, {
    path: "/residents/add",
    component: _3734ca7e,
    name: "residents-add"
  }, {
    path: "/admin/health-insurances/add",
    component: _0063aac5,
    name: "admin-health-insurances-add"
  }, {
    path: "/admin/healthcare-specialists/add",
    component: _6439471b,
    name: "admin-healthcare-specialists-add"
  }, {
    path: "/admin/representatives/add",
    component: _2fa5c491,
    name: "admin-representatives-add"
  }, {
    path: "/admin/residences/add",
    component: _2a7ae5c6,
    name: "admin-residences-add"
  }, {
    path: "/admin/roles/add",
    component: _7a6feb18,
    name: "admin-roles-add"
  }, {
    path: "/admin/staff/add",
    component: _d331678a,
    name: "admin-staff-add"
  }, {
    path: "/admin/volunteers/add",
    component: _f164cc7c,
    name: "admin-volunteers-add"
  }, {
    path: "/admin/health-insurances/edit/:id",
    component: _4d9c27c1,
    name: "admin-health-insurances-edit-id"
  }, {
    path: "/admin/healthcare-specialists/edit/:id",
    component: _64ae5daa,
    name: "admin-healthcare-specialists-edit-id"
  }, {
    path: "/admin/representatives/edit/:id?",
    component: _5cde4d75,
    name: "admin-representatives-edit-id"
  }, {
    path: "/admin/residences/edit/:residence?",
    component: _c1769a5e,
    name: "admin-residences-edit-residence"
  }, {
    path: "/admin/residences/view/:residence?",
    component: _ff068654,
    name: "admin-residences-view-residence"
  }, {
    path: "/admin/roles/edit/:id?",
    component: _024bb964,
    name: "admin-roles-edit-id"
  }, {
    path: "/admin/volunteers/edit/:id?",
    component: _fd1de238,
    name: "admin-volunteers-edit-id"
  }, {
    path: "/residences/:id",
    component: _e976fa42,
    name: "residences-id",
    children: [{
      path: "notes/:note?",
      component: _06740a5a,
      name: "residences-id-notes-note"
    }]
  }, {
    path: "/residents/:id",
    component: _541ee1d2,
    children: [{
      path: "",
      component: _1185285a,
      name: "residents-id"
    }, {
      path: "agreements",
      component: _2138545e,
      children: [{
        path: "",
        component: _6c32fb94,
        name: "residents-id-agreements"
      }, {
        path: "add",
        component: _3ef70e63,
        name: "residents-id-agreements-add"
      }, {
        path: "edit/:agreementId?",
        component: _4d51722d,
        name: "residents-id-agreements-edit-agreementId"
      }, {
        path: "view/:agreementId?",
        component: _7ea1e21c,
        name: "residents-id-agreements-view-agreementId"
      }]
    }, {
      path: "contact-persons",
      component: _321459ce,
      name: "residents-id-contact-persons"
    }, {
      path: "days-out",
      component: _44048b80,
      children: [{
        path: "",
        component: _8bcb1bfa,
        name: "residents-id-days-out"
      }, {
        path: "add",
        component: _43d75492,
        name: "residents-id-days-out-add"
      }, {
        path: "edit/:daysOutId?",
        component: _2f8df989,
        name: "residents-id-days-out-edit-daysOutId"
      }]
    }, {
      path: "dossier",
      component: _73061dd3,
      children: [{
        path: "",
        component: _58cbba16,
        name: "residents-id-dossier"
      }, {
        path: "add",
        component: _18518565,
        name: "residents-id-dossier-add"
      }]
    }, {
      path: "goals",
      component: _84538770,
      children: [{
        path: "",
        component: _6fc66ccb,
        name: "residents-id-goals"
      }, {
        path: ":goal",
        component: _0c59ebeb,
        name: "residents-id-goals-goal"
      }]
    }, {
      path: "health-insurance",
      component: _4cdc62d1,
      children: [{
        path: "",
        component: _c3646ed8,
        name: "residents-id-health-insurance"
      }, {
        path: "add",
        component: _393dc93a,
        name: "residents-id-health-insurance-add"
      }, {
        path: "edit/:contractId?",
        component: _65092195,
        name: "residents-id-health-insurance-edit-contractId"
      }]
    }, {
      path: "healthcare-specialists",
      component: _6a75f4aa,
      children: [{
        path: "",
        component: _b84258a6,
        name: "residents-id-healthcare-specialists"
      }, {
        path: "add",
        component: _d887ce88,
        name: "residents-id-healthcare-specialists-add"
      }]
    }, {
      path: "incidents",
      component: _4fe3bde9,
      children: [{
        path: "",
        component: _668b1ca8,
        name: "residents-id-incidents"
      }, {
        path: "add",
        component: _0e952a7b,
        name: "residents-id-incidents-add"
      }, {
        path: ":incident",
        component: _3d82868b,
        name: "residents-id-incidents-incident"
      }]
    }, {
      path: "legal-representatives",
      component: _30ebfa2c,
      name: "residents-id-legal-representatives"
    }, {
      path: "measurements",
      component: _8fca7142,
      children: [{
        path: "",
        component: _69e60aa2,
        name: "residents-id-measurements"
      }, {
        path: "add",
        component: _089e88f1,
        name: "residents-id-measurements-add"
      }, {
        path: "edit/:measurementTemplateInstanceId?",
        component: _b4930608,
        name: "residents-id-measurements-edit-measurementTemplateInstanceId"
      }, {
        path: "view/:measurementTemplateInstanceId?",
        component: _18fb5afe,
        name: "residents-id-measurements-view-measurementTemplateInstanceId"
      }]
    }, {
      path: "medications",
      component: _fd729be8,
      children: [{
        path: "",
        component: _cedf00e2,
        name: "residents-id-medications"
      }, {
        path: "add",
        component: _2f4b551e,
        name: "residents-id-medications-add"
      }, {
        path: "edit/:medicationId?",
        component: _16653312,
        name: "residents-id-medications-edit-medicationId"
      }]
    }, {
      path: "notes",
      component: _a64c826e,
      children: [{
        path: "",
        component: _62a44a8c,
        name: "residents-id-notes"
      }, {
        path: ":note",
        component: _132d19ea,
        name: "residents-id-notes-note"
      }]
    }, {
      path: "plan",
      component: _56f2055e,
      children: [{
        path: "",
        component: _313a8314,
        name: "residents-id-plan"
      }, {
        path: "add",
        component: _073a75e3,
        name: "residents-id-plan-add"
      }, {
        path: "edit/:planId?",
        component: _f2678828,
        name: "residents-id-plan-edit-planId"
      }, {
        path: "view/:planId?",
        component: _f2520ff2,
        name: "residents-id-plan-view-planId"
      }]
    }, {
      path: "representatives",
      component: _1e7867fe,
      name: "residents-id-representatives"
    }, {
      path: "risk-analysis",
      component: _1a8a2fa2,
      children: [{
        path: "",
        component: _5d68f4a5,
        name: "residents-id-risk-analysis"
      }, {
        path: "add",
        component: _58ca17b4,
        name: "residents-id-risk-analysis-add"
      }, {
        path: "edit/:riskAnalysisId?",
        component: _1530c746,
        name: "residents-id-risk-analysis-edit-riskAnalysisId"
      }, {
        path: "view/:riskAnalysisId?",
        component: _302e0d78,
        name: "residents-id-risk-analysis-view-riskAnalysisId"
      }]
    }, {
      path: "sports",
      component: _14854ab2,
      children: [{
        path: "",
        component: _7acf07ea,
        name: "residents-id-sports"
      }, {
        path: "add",
        component: _7e938f8e,
        name: "residents-id-sports-add"
      }, {
        path: "edit/:sportId?",
        component: _05488e6d,
        name: "residents-id-sports-edit-sportId"
      }]
    }, {
      path: "volunteer",
      component: _0ed76712,
      children: [{
        path: "",
        component: _812a4fd6,
        name: "residents-id-volunteer"
      }, {
        path: "add",
        component: _6bce1724,
        name: "residents-id-volunteer-add"
      }]
    }, {
      path: "representatives/add",
      component: _506b1610,
      name: "residents-id-representatives-add"
    }, {
      path: "representatives/:representativeId",
      component: _1142b026,
      name: "residents-id-representatives-representativeId"
    }]
  }, {
    path: "/staff/:id",
    component: _6eefe854,
    children: [{
      path: "",
      component: _54b9dfd7,
      name: "staff-id"
    }, {
      path: "contracts",
      component: _7eb64aa6,
      children: [{
        path: "",
        component: _0c6dbca9,
        name: "staff-id-contracts"
      }, {
        path: "add",
        component: _43a910b8,
        name: "staff-id-contracts-add"
      }, {
        path: ":contractId",
        component: _48183f03,
        name: "staff-id-contracts-contractId"
      }]
    }, {
      path: "dossier",
      component: _882a2be0,
      children: [{
        path: "",
        component: _78c3a493,
        name: "staff-id-dossier"
      }, {
        path: "add",
        component: _e56829bc,
        name: "staff-id-dossier-add"
      }]
    }, {
      path: "incidents",
      component: _1a732234,
      children: [{
        path: "",
        component: _633c9e2e,
        name: "staff-id-incidents"
      }, {
        path: "add",
        component: _2e8d14f8,
        name: "staff-id-incidents-add"
      }, {
        path: ":incident",
        component: _e2644170,
        name: "staff-id-incidents-incident"
      }]
    }]
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
