import { render, staticRenderFns } from "./DashboardLayout.vue?vue&type=template&id=ea57fa62&"
import script from "./DashboardLayout.vue?vue&type=script&lang=js&"
export * from "./DashboardLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Notifications: require('/src/components/argon-core/NotificationPlugin/Notifications.vue').default,BaseButton: require('/src/components/argon-core/BaseButton.vue').default,Modal: require('/src/components/argon-core/Modal.vue').default,SidebarItem: require('/src/components/argon-core/SidebarPlugin/SidebarItem.vue').default,SideBar: require('/src/components/argon-core/SidebarPlugin/SideBar.vue').default,DashboardNavbar: require('/src/components/layouts/argon/DashboardNavbar.vue').default,Autologout: require('/src/components/custom/Autologout.vue').default})
