import Vue from 'vue'

import {
  ValidationObserver,
  ValidationProvider,
  configure,
  extend
} from 'vee-validate'

import {
  required,
  email,
  integer,
  double,
  min,
  max,
  between,
  // eslint-disable-next-line camelcase
  min_value,
  // eslint-disable-next-line camelcase
  max_value,
  numeric,
  regex,
  length,
  // eslint-disable-next-line camelcase
  alpha_dash
} from 'vee-validate/dist/rules'

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
  }
})

// Install the validations
extend('required', required)
extend('email', email)
extend('integer', integer)
extend('double', double)
extend('min', min)
extend('max', max)
extend('between', between)
extend('min_value', min_value)
extend('max_value', max_value)
extend('numeric', numeric)
extend('regex', regex)
extend('length', length)
extend('alpha_dash', alpha_dash)

// Custom validation rules
extend('minmax', {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max']
})

extend('zipcode', {
  validate(value) {
    return /^[1-9][0-9]{3} ?[a-z]{2}$/i.test(value)
  }
})

extend('username', {
  validate(value) {
    return /^[abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789\-._@+]*$/.test(
      value
    )
  }
})

// Regex for valid Dutch phone numbers
extend('phonenumber', {
  validate(value) {
    return /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(
      value
    )
  }
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  }
})

extend('listOfEmails', {
  validate(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
      return true
    }
    return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
      value
    )
  },
  message:
    'The email list is not correct. Please check again all the emails written'
})

// Validation using https://www.npmjs.com/package/awesome-phonenumber
extend('phoneNumberWithGoogleValidation', {
  validate(value) {
    if (typeof value === 'undefined' || value === null || value === '') {
      return true
    }
    const PhoneNumber = require('awesome-phonenumber')
    const pn = PhoneNumber(value, 'NL')
    return pn.isValid()
  },
  message: 'Please use an existing phone number'
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
