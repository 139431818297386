import Vue from 'vue'
// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import '@/polyfills'

// Notifications plugin. Used on Notifications page
// import Notifications from 'vue-notification'
import Notifications from '~/components/argon-core/NotificationPlugin'

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from '~/components/argon-core/SidebarPlugin'

import './globalDirectives'
import './globalComponents'

Vue.use(SideBar)
Vue.use(Notifications)
