const resource = 'dayout'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  allByResident(residentId) {
    return $axios.$get(`/${resource}?$filter=residentId eq ${residentId}`)
  },
  allById(formId) {
    return $axios.$get(`/${resource}?$filter=id eq ${formId}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  }
})
