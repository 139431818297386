const resource = 'goalEvent'

export default $axios => ({
  getAll() {
    return $axios.$get(`/${resource}`)
  },
  getAllByResidence(residenceId) {
    return $axios.$get(`/${resource}?$filter=residenceId eq ${residenceId}`)
  },
  getGoalEventPerUntilToday(day, residenceId) {
    return $axios.$get(
      `/${resource}?$filter=date le ${day} AND residenceIds/any(residenceId: residenceId eq ${residenceId})&orderby=date asc`
    )
  },
  getGoalforDashboard(residenceId) {
    const today = new Date()
    const tomorrow = new Date()
    tomorrow.setDate(today.getDate() + 1)

    return $axios.$get(
      `/${resource}?$filter=residenceIds/any(residenceId: residenceId eq ${residenceId}) and date ge ${today
        .toISOString()
        .slice(0, 11)}00:00:00Z and date le ${tomorrow
        .toISOString()
        .slice(0, 11)}23:59:59Z
        &$orderBy=date asc`
    )
  },
  delete(goalEventId) {
    return $axios.delete(`/${resource}/${goalEventId}`)
  }
})
