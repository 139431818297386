import Vue from 'vue'
const getDefaultState = () => ({
  currentResidence: null,
  preferredLanguage: null,
  userAccessLevel: null,
  currentDashboardResident: null,
  claims: null,
  isGeneral: null
})

export const state = () => getDefaultState()

export const mutations = {
  SET_CURRENT_RESIDENCE(state, payload) {
    state.currentResidence = payload
  },
  SET_CURRENT_DASHBOARD_RESIDENT(state, payload) {
    state.currentDashboardResident = payload
  },
  SET_PREFERRED_LANGUAGE(state, payload) {
    state.preferredLanguage = payload
  },
  SET_USER_ACCESS_LEVEL(state, payload) {
    state.userAccessLevel = payload
  },
  SET_CURRENT_CLAIMS(state, payload) {
    Vue.set(state, 'claims', [...payload])
  },
  SET_IS_GENERAL(state, payload) {
    state.isGeneral = payload
  },
  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}
export const actions = {
  setCurrentResidence({ dispatch, commit }, payload) {
    commit('SET_CURRENT_RESIDENCE', payload)
    dispatch('setCurrentClaims')
  },
  setCurrentDashboardResidence({ dispatch, commit }, payload) {
    commit('SET_CURRENT_DASHBOARD_RESIDENT', payload)
  },
  setPreferredLanguage({ commit }, payload) {
    commit('SET_PREFERRED_LANGUAGE', payload)
  },
  async setCurrentClaims({ state, commit }) {
    let allClaims = await this.$api.roleClaim.currentClaims()
    const externalUserClaims = await this.$api.userClaim.currentClaims()
    allClaims = allClaims.filter(
      c => c.residenceId === null || c.residenceId === state.currentResidence
    )
    const roleClaims = []
    allClaims.forEach(c => {
      roleClaims.push(...c.roleClaims)
    })
    roleClaims.push(...externalUserClaims)
    const userAccessLevels = roleClaims
      .filter(rc => rc.name === 'UserAccessLevel')
      .map(rc => rc.value)
    const userAccessLevel = Math.max.apply(Math, userAccessLevels)
    const { isGeneral } = await this.$api.roleClaim.currentUserIsGeneral()
    commit('SET_IS_GENERAL', isGeneral)
    commit('SET_USER_ACCESS_LEVEL', userAccessLevel)
    commit('SET_CURRENT_CLAIMS', roleClaims)
  },
  reset({ commit }) {
    commit('RESET')
  }
}

export const getters = {
  hasClaim: state => (claimName, value) => {
    return state.claims
      ? state.claims.some(c => c.name === claimName && c.value === value)
      : false
  }
}
