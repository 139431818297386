import { render, staticRenderFns } from "./Autologout.vue?vue&type=template&id=63f3e962&"
import script from "./Autologout.vue?vue&type=script&lang=js&"
export * from "./Autologout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/src/components/argon-core/Modal.vue').default})
