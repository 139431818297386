const resource = 'goal'

export default $axios => ({
  getAll() {
    return $axios.$get(`/${resource}`)
  },
  getGoalsForSpecificResident(residentId) {
    return $axios.$get(
      `/${resource}?$filter=residentId eq ${residentId}&$orderby=endDate desc`
    )
  },
  async getGoal(goalId, mapToObject = false) {
    let request = $axios.$get(`/${resource}?$filter=id eq ${goalId}`)
    if (!mapToObject) {
      return request;
    }
    let result = await request.catch((err) => {
      this.$notifyError(err)
      return null;
    });
    if (result && Array.isArray(result)) {
      return result[0];
    }
    return result;
  },
  create(payload) {
    return $axios.post(`/${resource}`, payload)
  },
  update(goalId, payload) {
    return $axios.put(`/${resource}/${goalId}`, payload)
  },
  archive(goalId, payload) {
    return $axios.post(`/${resource}/${goalId}/archive`, payload)
  },
  restore(goalId, payload) {
    return $axios.post(`/${resource}/${goalId}/restore`, payload)
  },
  delete(goalId) {
    return $axios.delete(`/${resource}/${goalId}`)
  },
  customFilter(goals) {
    return $axios.$get(`/${resource}?$filter=${goals}`)
  }
})
