
  export default {
    name: 'notification',
    components: {
      contentRender: {
        props: ['component'],
        render: h => h(this.component)
      }
    },
    props: {
      message: String,
      title: {
        type: String,
        description: 'Notification title'
      },
      icon: {
        type: String,
        description: 'Notification icon'
      },
      verticalAlign: {
        type: String,
        default: 'top',
        validator: value => {
          let acceptedValues = ['top', 'bottom'];
          return acceptedValues.indexOf(value) !== -1;
        },
        description: 'Vertical alignment of notification (top|bottom)'
      },
      horizontalAlign: {
        type: String,
        default: 'right',
        validator: value => {
          let acceptedValues = ['left', 'center', 'right'];
          return acceptedValues.indexOf(value) !== -1;
        },
        description: 'Horizontal alignment of notification (left|center|right)'
      },
      type: {
        type: String,
        default: 'info',
        validator: value => {
          let acceptedValues = [
            'default',
            'info',
            'primary',
            'danger',
            'warning',
            'success'
          ];
          return acceptedValues.indexOf(value) !== -1;
        },
        description: 'Notification type of notification (default|info|primary|danger|warning|success)'
      },
      timeout: {
        type: Number,
        default: 5000,
        validator: value => {
          return value >= 0;
        },
        description: 'Notification timeout (closes after X milliseconds). Default is 5000 (5s)'
      },
      timestamp: {
        type: Date,
        default: () => new Date(),
        description: 'Notification timestamp (used internally to handle notification removal correctly)'
      },
      component: {
        type: [Object, Function],
        description: 'Custom content component. Cane be a `.vue` component or render function'
      },
      showClose: {
        type: Boolean,
        default: true,
        description: 'Whether to show close button'
      },
      closeOnClick: {
        type: Boolean,
        default: true,
        description: 'Whether to close notification when clicking it\' body'
      },
      clickHandler: {
        type: Function,
        description: 'Custom notification click handler'
      }
    },
    data() {
      return {
        elmHeight: 0
      };
    },
    computed: {
      hasIcon() {
        return this.icon && this.icon.length > 0;
      },
      alertType() {
        return `alert-${this.type}`;
      },
      customPosition() {
        let initialMargin = 20;
        let alertHeight = this.elmHeight + 10;
        let sameAlertsCount = this.$notifications.state.filter(alert => {
          return (
            alert.horizontalAlign === this.horizontalAlign &&
            alert.verticalAlign === this.verticalAlign &&
            alert.timestamp <= this.timestamp
          );
        }).length;
        if (this.$notifications.settings.overlap) {
          sameAlertsCount = 1;
        }
        let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
        let styles = {};
        if (this.verticalAlign === 'top') {
          styles.top = `${pixels}px`;
        } else {
          styles.bottom = `${pixels}px`;
        }
        return styles;
      }
    },
    methods: {
      close() {
        this.$emit('close', this.timestamp);
      },
      tryClose(evt) {
        if (this.clickHandler) {
          this.clickHandler(evt, this);
        }
        if (this.closeOnClick) {
          this.close();
        }
      }
    },
    mounted() {
      this.elmHeight = this.$el.clientHeight;
      if (this.timeout) {
        setTimeout(this.close, this.timeout);
      }
    }
  };
