export default $axios => ({
  Print(payload) {
    let url = `/print?residentId=${payload.residentId}`

    if (payload.dataKeys) {
      url += `&dataTypes=${payload.dataKeys}`
    }
    if (payload.from) {
      url += `&from=${payload.from}`
    }
    if (payload.to) {
      url += `&to=${payload.to}`
    }
    if (payload.dataObjectId) {
      url += `&dataObjectId=${payload.dataObjectId}`
    }
    return $axios.get(url, { responseType: 'blob' })
  }
})
