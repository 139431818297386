
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import { mapState, mapGetters, mapActions } from 'vuex'
import DashboardNavbar from '~/components/layouts/argon/DashboardNavbar.vue'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

export default {
  name: 'DashboardLayout',
  middleware: 'auth',
  components: {
    DashboardNavbar
  },
  data() {
    return {
      supportModal: false,
      vuexUnsubscribe: null
    }
  },
  computed: {
    ...mapState('ui', ['showSupportModal']),
    ...mapGetters('userSettings', ['hasClaim']),
    ...mapState('auth', ['userData'])
  },
  mounted() {
    this.initScrollbar()
    this.setCurrentClaims()
    this.getUserInfo()
    this.vuexUnsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'userSettings/SET_PREFERRED_LANGUAGE') {
        if (state.userSettings.preferredLanguage === 0) {
          this.$i18n.setLocale('en')
        } else if (state.userSettings.preferredLanguage === 1) {
          this.$i18n.setLocale('nl')
        }
      }
    })
  },
  destroyed() {
    this.vuexUnsubscribe()
  },
  methods: {
    ...mapActions('ui', ['toggleSupportModal']),
    ...mapActions('auth', ['getUserInfo']),
    ...mapActions('userSettings', ['setCurrentClaims']),
    initScrollbar() {
      const isWindows = navigator.platform.startsWith('Win')
      if (isWindows) {
        initScrollbar('scrollbar-inner')
      }
    },
    closeSupportModal() {
      this.toggleSupportModal(false)
    }
  }
}
