import qs from 'qs'

const resource = 'connect'
const authUsername = 'ApiClient'
const authPassword = 'secret'

export default $axios => ({
  getToken(username, password) {
    const payload = {
      username,
      password,
      grant_type: 'password',
      scope: 'offline_access',
      response_type: 'id_token'
    }

    return $axios.$post(`/${resource}/token`, qs.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: authUsername,
        password: authPassword
      }
    })
  },
  refreshToken(refreshToken) {
    const payload = {
      grant_type: 'refresh_token',
      response_type: 'id_token',
      refresh_token: refreshToken
    }

    return $axios.$post(`/${resource}/token`, qs.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      auth: {
        username: authUsername,
        password: authPassword
      }
    })
  }
})
