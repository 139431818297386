const resource = 'documents'

export default $axios => ({
  addPictureToResidence(residenceID, picture) {
    const payload = new FormData()
    payload.append('documentForCreation.Name', residenceID)
    payload.append('documentForCreation.Type', '1')
    payload.append('documentForCreation.ObjectId', residenceID)
    payload.append('documentForCreation.ReferenceObjectType', '2')
    payload.append('file', picture)
    return $axios.$post(`/${resource}`, payload)
  },
  getAllResidencesProfilePicture() {
    return $axios.$get(
      `/${resource}?$filter=referenceObjectType eq 'Residence'`
    )
  },
  getAllUserProfilePicture() {
    return $axios.$get(
      `/${resource}?$filter=referenceObjectType eq 'User' and type eq 'ProfilePicture'`
    )
  },
  getDocument(id) {
    return $axios.$get(`/${resource}?$filter=objectId eq ${id}`)
  },
  getDocumentById(id) {
    return $axios.$get(`/${resource}/${id}`, {
      responseType: 'blob'
    })
  },
  getDocumentsForResident(residentId) {
    return $axios.$get(
      `/${resource}?$filter=objectId eq ${residentId} and type ne 'ProfilePicture'`
    )
  },
  getResidenceProfilePicture(documentId) {
    return $axios.$get(`/${resource}/${documentId}`)
  },
  getUserProfilePicture(userId) {
    return $axios.$get(
      `/${resource}?$filter=objectId eq ${userId} and type eq 'ProfilePicture'`
    )
  },
  addPictureToUser(userId, picture) {
    const payload = new FormData()
    payload.append('documentForCreation.Name', userId)
    payload.append('documentForCreation.Type', '1')
    payload.append('documentForCreation.ObjectId', userId)
    payload.append('documentForCreation.ReferenceObjectType', '1')
    payload.append('file', picture)
    return $axios.$post(`/${resource}`, payload)
  },
  delete(documentId) {
    return $axios.$delete(`/${resource}/${documentId}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  archive(documentId){
    return $axios.$post(`${resource}/${documentId}/archive`)
  },
  unArchive(documentId){
    return $axios.$post(`${resource}/${documentId}/unarchive`)
  }
})
