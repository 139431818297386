const resource = 'agendaEvent'

export default $axios => ({
  getAll() {
    return $axios.$get(`/${resource}?$orderby=date asc`)
  },
  getAllAfterCurrentTime(today) {
    return $axios.$get(
      `/${resource}?$filter=date ge ${today}&$orderby=date asc`
    )
  },
  getAllAfterCurrentTimeForResident(today, resident) {
    return $axios.$get(
      `/${resource}?$filter=date ge ${today} and residentId eq ${resident}&$orderby=date asc`
    )
  },
  getAgendaEventPerDay(day) {
    return $axios.$get(`/${resource}?$filter=date eq ${day}`)
  },
  delete(agendaEventId) {
    return $axios.delete(`/${resource}/${agendaEventId}`)
  },
  update(agendaEventId, messageString) {
    return $axios.put(`/${resource}/${agendaEventId}`, {
      messageOverride: messageString
    })
  }
})
