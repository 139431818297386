export default (ctx, inject) => {
  const notifyError = (error, message) => {
    if (!message) {
      message = 'messages.defaultError'
    }

    $nuxt.$notify({
      type: 'danger',
      title: $nuxt.$t(message)
    })

    $nuxt.$sentry.captureException(error)
  }

  inject('notifyError', notifyError);
}
