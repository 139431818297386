const resource = 'riskanalysis'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  allByResident(residentId) {
    return $axios.$get(
      `/${resource}?$filter=userId eq ${residentId}&$orderby=analysisDate desc`
    )
  },
  allById(riskAnalysisId) {
    return $axios.$get(`/${resource}?$filter=id eq ${riskAnalysisId}`)
  },
  allQuestions() {
    return $axios.$get(`/${resource}/questions`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  }
})
