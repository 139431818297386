
import { mapState, mapActions } from 'vuex'
import { Select, Option } from 'element-ui'
import BaseNav from '@/components/argon-core/Navbar/BaseNav.vue'
import InitialsProfilePicture from '../../custom/UI/InitialsProfilePicture'

export default {
  components: {
    InitialsProfilePicture,
    BaseNav,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    type: {
      type: String,
      default: 'default', // default|light
      description:
        'Look of the dashboard navbar. Default (Green) or light (gray)'
    }
  },
  data() {
    return {
      activeNotifications: false,
      selects: {
        selected: '',
        residences: [],
        loading: false
      }
    }
  },
  watch: {
    $route(to, from) {
      if (!to.path.startsWith('/admin')) {
        this.getResidences()
      }
    }
  },
  computed: {
    ...mapState('auth', ['username']),
    ...mapState('userSettings', ['currentResidence']),
    routeName() {
      const { name } = this.$route
      return this.capitalizeFirstLetter(name)
    }
  },
  mounted() {
    this.getResidences()
  },
  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('userSettings', ['setCurrentResidence']),
    ...mapActions('ui', ['toggleSupportModal']),
    async getResidences() {
      try {
        this.selects.loading = true
        const residences = await this.$api.residence.getResidences()
        this.selects.residences = residences
        this.selects.loading = false
      } catch (error) {}
    },
    openSupportModal() {
      this.toggleSupportModal(true)
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false)
    },
    onChange(event) {
      this.setCurrentResidence(event)
    },
    onLogout() {
      this.logout()
      this.$router.push('/login')
    }
  }
}
