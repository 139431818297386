const resource = 'goalNotification'

export default $axios => ({
  getAll() {
    return $axios.$get(`/${resource}`)
  },
  archive(id) {
    return $axios.$post(`/${resource}/${id}/archive`)
  }
})
