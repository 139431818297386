export const Badge = () => import('../../components/argon-core/Badge.vue' /* webpackChunkName: "components/badge" */).then(c => wrapFunctional(c.default || c))
export const BaseAlert = () => import('../../components/argon-core/BaseAlert.vue' /* webpackChunkName: "components/base-alert" */).then(c => wrapFunctional(c.default || c))
export const BaseButton = () => import('../../components/argon-core/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseDropdown = () => import('../../components/argon-core/BaseDropdown.vue' /* webpackChunkName: "components/base-dropdown" */).then(c => wrapFunctional(c.default || c))
export const BaseHeader = () => import('../../components/argon-core/BaseHeader.vue' /* webpackChunkName: "components/base-header" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/argon-core/BasePagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BaseProgress = () => import('../../components/argon-core/BaseProgress.vue' /* webpackChunkName: "components/base-progress" */).then(c => wrapFunctional(c.default || c))
export const BaseSlider = () => import('../../components/argon-core/BaseSlider.vue' /* webpackChunkName: "components/base-slider" */).then(c => wrapFunctional(c.default || c))
export const BaseSwitch = () => import('../../components/argon-core/BaseSwitch.vue' /* webpackChunkName: "components/base-switch" */).then(c => wrapFunctional(c.default || c))
export const BaseTable = () => import('../../components/argon-core/BaseTable.vue' /* webpackChunkName: "components/base-table" */).then(c => wrapFunctional(c.default || c))
export const ButtonCheckbox = () => import('../../components/argon-core/ButtonCheckbox.vue' /* webpackChunkName: "components/button-checkbox" */).then(c => wrapFunctional(c.default || c))
export const ButtonRadioGroup = () => import('../../components/argon-core/ButtonRadioGroup.vue' /* webpackChunkName: "components/button-radio-group" */).then(c => wrapFunctional(c.default || c))
export const CloseButton = () => import('../../components/argon-core/CloseButton.vue' /* webpackChunkName: "components/close-button" */).then(c => wrapFunctional(c.default || c))
export const LoadingPanel = () => import('../../components/argon-core/LoadingPanel.vue' /* webpackChunkName: "components/loading-panel" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/argon-core/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ArgonCore = () => import('../../components/argon-core/index.js' /* webpackChunkName: "components/argon-core" */).then(c => wrapFunctional(c.default || c))
export const Autologout = () => import('../../components/custom/Autologout.vue' /* webpackChunkName: "components/autologout" */).then(c => wrapFunctional(c.default || c))
export const DeleteModal = () => import('../../components/custom/DeleteModal.vue' /* webpackChunkName: "components/delete-modal" */).then(c => wrapFunctional(c.default || c))
export const DossierPreviewModal = () => import('../../components/custom/DossierPreviewModal.vue' /* webpackChunkName: "components/dossier-preview-modal" */).then(c => wrapFunctional(c.default || c))
export const PrintCard = () => import('../../components/custom/PrintCard.vue' /* webpackChunkName: "components/print-card" */).then(c => wrapFunctional(c.default || c))
export const RecoveryModal = () => import('../../components/custom/RecoveryModal.vue' /* webpackChunkName: "components/recovery-modal" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/argon-core/Breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbItem = () => import('../../components/argon-core/Breadcrumb/BreadcrumbItem.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c))
export const RouteBreadcrumb = () => import('../../components/argon-core/Breadcrumb/RouteBreadcrumb.vue' /* webpackChunkName: "components/route-breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const Card = () => import('../../components/argon-core/Cards/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c))
export const StatsCard = () => import('../../components/argon-core/Cards/StatsCard.vue' /* webpackChunkName: "components/stats-card" */).then(c => wrapFunctional(c.default || c))
export const BarChart = () => import('../../components/argon-core/Charts/BarChart.js' /* webpackChunkName: "components/bar-chart" */).then(c => wrapFunctional(c.default || c))
export const DoughnutChart = () => import('../../components/argon-core/Charts/DoughnutChart.js' /* webpackChunkName: "components/doughnut-chart" */).then(c => wrapFunctional(c.default || c))
export const LineChart = () => import('../../components/argon-core/Charts/LineChart.js' /* webpackChunkName: "components/line-chart" */).then(c => wrapFunctional(c.default || c))
export const PieChart = () => import('../../components/argon-core/Charts/PieChart.js' /* webpackChunkName: "components/pie-chart" */).then(c => wrapFunctional(c.default || c))
export const Config = () => import('../../components/argon-core/Charts/config.js' /* webpackChunkName: "components/config" */).then(c => wrapFunctional(c.default || c))
export const OptionHelpers = () => import('../../components/argon-core/Charts/optionHelpers.js' /* webpackChunkName: "components/option-helpers" */).then(c => wrapFunctional(c.default || c))
export const Collapse = () => import('../../components/argon-core/Collapse/Collapse.vue' /* webpackChunkName: "components/collapse" */).then(c => wrapFunctional(c.default || c))
export const CollapseItem = () => import('../../components/argon-core/Collapse/CollapseItem.vue' /* webpackChunkName: "components/collapse-item" */).then(c => wrapFunctional(c.default || c))
export const Comment = () => import('../../components/argon-core/Feed/Comment.vue' /* webpackChunkName: "components/comment" */).then(c => wrapFunctional(c.default || c))
export const BaseCheckbox = () => import('../../components/argon-core/Inputs/BaseCheckbox.vue' /* webpackChunkName: "components/base-checkbox" */).then(c => wrapFunctional(c.default || c))
export const BaseInput = () => import('../../components/argon-core/Inputs/BaseInput.vue' /* webpackChunkName: "components/base-input" */).then(c => wrapFunctional(c.default || c))
export const BaseRadio = () => import('../../components/argon-core/Inputs/BaseRadio.vue' /* webpackChunkName: "components/base-radio" */).then(c => wrapFunctional(c.default || c))
export const DropzoneFileUpload = () => import('../../components/argon-core/Inputs/DropzoneFileUpload.vue' /* webpackChunkName: "components/dropzone-file-upload" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/argon-core/Inputs/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const HtmlEditor = () => import('../../components/argon-core/Inputs/HtmlEditor.vue' /* webpackChunkName: "components/html-editor" */).then(c => wrapFunctional(c.default || c))
export const IconCheckbox = () => import('../../components/argon-core/Inputs/IconCheckbox.vue' /* webpackChunkName: "components/icon-checkbox" */).then(c => wrapFunctional(c.default || c))
export const TagsInput = () => import('../../components/argon-core/Inputs/TagsInput.vue' /* webpackChunkName: "components/tags-input" */).then(c => wrapFunctional(c.default || c))
export const BaseNav = () => import('../../components/argon-core/Navbar/BaseNav.vue' /* webpackChunkName: "components/base-nav" */).then(c => wrapFunctional(c.default || c))
export const NavbarToggleButton = () => import('../../components/argon-core/Navbar/NavbarToggleButton.vue' /* webpackChunkName: "components/navbar-toggle-button" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/argon-core/NotificationPlugin/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/argon-core/NotificationPlugin/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const NotificationPlugin = () => import('../../components/argon-core/NotificationPlugin/index.js' /* webpackChunkName: "components/notification-plugin" */).then(c => wrapFunctional(c.default || c))
export const SideBar = () => import('../../components/argon-core/SidebarPlugin/SideBar.vue' /* webpackChunkName: "components/side-bar" */).then(c => wrapFunctional(c.default || c))
export const SidebarItem = () => import('../../components/argon-core/SidebarPlugin/SidebarItem.vue' /* webpackChunkName: "components/sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const SidebarPlugin = () => import('../../components/argon-core/SidebarPlugin/index.js' /* webpackChunkName: "components/sidebar-plugin" */).then(c => wrapFunctional(c.default || c))
export const Tab = () => import('../../components/argon-core/Tabs/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c))
export const Tabs = () => import('../../components/argon-core/Tabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c))
export const TimeLine = () => import('../../components/argon-core/Timeline/TimeLine.vue' /* webpackChunkName: "components/time-line" */).then(c => wrapFunctional(c.default || c))
export const TimeLineItem = () => import('../../components/argon-core/Timeline/TimeLineItem.vue' /* webpackChunkName: "components/time-line-item" */).then(c => wrapFunctional(c.default || c))
export const AsyncWorldMap = () => import('../../components/argon-core/WorldMap/AsyncWorldMap.vue' /* webpackChunkName: "components/async-world-map" */).then(c => wrapFunctional(c.default || c))
export const WorldMap = () => import('../../components/argon-core/WorldMap/WorldMap.vue' /* webpackChunkName: "components/world-map" */).then(c => wrapFunctional(c.default || c))
export const AddDocumentForm = () => import('../../components/custom/Forms/AddDocumentForm.vue' /* webpackChunkName: "components/add-document-form" */).then(c => wrapFunctional(c.default || c))
export const AgreementsForm = () => import('../../components/custom/Forms/AgreementsForm.vue' /* webpackChunkName: "components/agreements-form" */).then(c => wrapFunctional(c.default || c))
export const ContractsForm = () => import('../../components/custom/Forms/ContractsForm.vue' /* webpackChunkName: "components/contracts-form" */).then(c => wrapFunctional(c.default || c))
export const DaysOutForm = () => import('../../components/custom/Forms/DaysOutForm.vue' /* webpackChunkName: "components/days-out-form" */).then(c => wrapFunctional(c.default || c))
export const GeneralPrintingForm = () => import('../../components/custom/Forms/GeneralPrintingForm.vue' /* webpackChunkName: "components/general-printing-form" */).then(c => wrapFunctional(c.default || c))
export const HealthcareSpecialistsFrom = () => import('../../components/custom/Forms/HealthcareSpecialistsFrom.vue' /* webpackChunkName: "components/healthcare-specialists-from" */).then(c => wrapFunctional(c.default || c))
export const IncidentsForm = () => import('../../components/custom/Forms/IncidentsForm.vue' /* webpackChunkName: "components/incidents-form" */).then(c => wrapFunctional(c.default || c))
export const IncidentsStaffForm = () => import('../../components/custom/Forms/IncidentsStaffForm.vue' /* webpackChunkName: "components/incidents-staff-form" */).then(c => wrapFunctional(c.default || c))
export const PlanForm = () => import('../../components/custom/Forms/PlanForm.vue' /* webpackChunkName: "components/plan-form" */).then(c => wrapFunctional(c.default || c))
export const ResidenceForm = () => import('../../components/custom/Forms/ResidenceForm.vue' /* webpackChunkName: "components/residence-form" */).then(c => wrapFunctional(c.default || c))
export const ResidentForm = () => import('../../components/custom/Forms/ResidentForm.vue' /* webpackChunkName: "components/resident-form" */).then(c => wrapFunctional(c.default || c))
export const RiskAnalysisForm = () => import('../../components/custom/Forms/RiskAnalysisForm.vue' /* webpackChunkName: "components/risk-analysis-form" */).then(c => wrapFunctional(c.default || c))
export const UserAccessForm = () => import('../../components/custom/Forms/UserAccessForm.vue' /* webpackChunkName: "components/user-access-form" */).then(c => wrapFunctional(c.default || c))
export const UserForm = () => import('../../components/custom/Forms/UserForm.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c))
export const UserGeneralForm = () => import('../../components/custom/Forms/UserGeneralForm.vue' /* webpackChunkName: "components/user-general-form" */).then(c => wrapFunctional(c.default || c))
export const VolunteerForm = () => import('../../components/custom/Forms/VolunteerForm.vue' /* webpackChunkName: "components/volunteer-form" */).then(c => wrapFunctional(c.default || c))
export const UserSideMenu = () => import('../../components/custom/Menus/UserSideMenu.vue' /* webpackChunkName: "components/user-side-menu" */).then(c => wrapFunctional(c.default || c))
export const ContractsTable = () => import('../../components/custom/Tables/ContractsTable.vue' /* webpackChunkName: "components/contracts-table" */).then(c => wrapFunctional(c.default || c))
export const DossierTable = () => import('../../components/custom/Tables/DossierTable.vue' /* webpackChunkName: "components/dossier-table" */).then(c => wrapFunctional(c.default || c))
export const IncidentsTable = () => import('../../components/custom/Tables/IncidentsTable.vue' /* webpackChunkName: "components/incidents-table" */).then(c => wrapFunctional(c.default || c))
export const PlanTable = () => import('../../components/custom/Tables/PlanTable.vue' /* webpackChunkName: "components/plan-table" */).then(c => wrapFunctional(c.default || c))
export const InitialsProfilePicture = () => import('../../components/custom/UI/InitialsProfilePicture.vue' /* webpackChunkName: "components/initials-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const PeopleList = () => import('../../components/custom/UI/PeopleList.vue' /* webpackChunkName: "components/people-list" */).then(c => wrapFunctional(c.default || c))
export const ResidencesPicture = () => import('../../components/custom/UI/ResidencesPicture.vue' /* webpackChunkName: "components/residences-picture" */).then(c => wrapFunctional(c.default || c))
export const UserProfilePicture = () => import('../../components/custom/UI/UserProfilePicture.vue' /* webpackChunkName: "components/user-profile-picture" */).then(c => wrapFunctional(c.default || c))
export const Content = () => import('../../components/layouts/argon/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c))
export const ContentFooter = () => import('../../components/layouts/argon/ContentFooter.vue' /* webpackChunkName: "components/content-footer" */).then(c => wrapFunctional(c.default || c))
export const DashboardNavbar = () => import('../../components/layouts/argon/DashboardNavbar.vue' /* webpackChunkName: "components/dashboard-navbar" */).then(c => wrapFunctional(c.default || c))
export const GoalsCard = () => import('../../components/custom/UI/Goals/GoalsCard.vue' /* webpackChunkName: "components/goals-card" */).then(c => wrapFunctional(c.default || c))
export const GoalsDetailedCard = () => import('../../components/custom/UI/Goals/GoalsDetailedCard.vue' /* webpackChunkName: "components/goals-detailed-card" */).then(c => wrapFunctional(c.default || c))
export const GoalsList = () => import('../../components/custom/UI/Goals/GoalsList.vue' /* webpackChunkName: "components/goals-list" */).then(c => wrapFunctional(c.default || c))
export const IncidentReporterColumn = () => import('../../components/custom/UI/Incidents/IncidentReporterColumn.vue' /* webpackChunkName: "components/incident-reporter-column" */).then(c => wrapFunctional(c.default || c))
export const NoteComment = () => import('../../components/custom/UI/Notes/NoteComment.vue' /* webpackChunkName: "components/note-comment" */).then(c => wrapFunctional(c.default || c))
export const NotesCard = () => import('../../components/custom/UI/Notes/NotesCard.vue' /* webpackChunkName: "components/notes-card" */).then(c => wrapFunctional(c.default || c))
export const NotesUserProfile = () => import('../../components/custom/UI/Notes/NotesUserProfile.vue' /* webpackChunkName: "components/notes-user-profile" */).then(c => wrapFunctional(c.default || c))
export const RiskAnalysisReporterColumn = () => import('../../components/custom/UI/RiskAnalysis/RiskAnalysisReporterColumn.vue' /* webpackChunkName: "components/risk-analysis-reporter-column" */).then(c => wrapFunctional(c.default || c))
export const DashboardAgenda = () => import('../../components/custom/UI/Dashboard/Agenda/DashboardAgenda.vue' /* webpackChunkName: "components/dashboard-agenda" */).then(c => wrapFunctional(c.default || c))
export const DashboardGoals = () => import('../../components/custom/UI/Dashboard/Goals/DashboardGoals.vue' /* webpackChunkName: "components/dashboard-goals" */).then(c => wrapFunctional(c.default || c))
export const DashboardNotes = () => import('../../components/custom/UI/Dashboard/Notes/DashboardNotes.vue' /* webpackChunkName: "components/dashboard-notes" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
