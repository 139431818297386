const resource = 'userclaim'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  currentClaims() {
    return $axios.$get(`/${resource}/currentClaims`)
  },
  show(userId, residentId) {
    return $axios.$get(
      `/${resource}?$filter=userId eq ${userId} and residentId eq ${residentId}`
    )
  },
  showByUser(userId) {
    return $axios.$get(`/${resource}?$filter=userId eq ${userId}`)
  },
  showByResident(residentId) {
    return $axios.$get(`/${resource}?$filter=residentId eq ${residentId}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(payload) {
    return $axios.$put(`/${resource}`, payload)
  },
  delete(payload) {
    return $axios({
      url: `/${resource}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: payload
    })
  }
})
