const getDefaultState = () => ({
  token: null,
  refreshToken: null,
  username: null,
  userId: null,
  userData: null
})

export const state = () => getDefaultState()

export const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_TOKEN_EXPIRES_IN(state, expiresIn) {
    state.expiresIn = expiresIn
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    state.refreshToken = refreshToken
  },
  SET_USERNAME(state, payload) {
    state.username = payload
  },
  SET_ID(state, payload) {
    state.userId = payload
  },
  SET_USERDATA(state, payload) {
    state.userData = payload
  },
  RESET(state) {
    Object.assign(state, getDefaultState())
  }
}
export const actions = {
  async login({ dispatch }, { username, password }) {
    const payload = await this.$api.connect.getToken(username, password)
    this.$sentry.setUser({ username: username })
    dispatch('setUsername', username)
    dispatch('setTokens', {
      token: payload.access_token,
      tokenType: payload.token_type,
      refreshToken: payload.refresh_token,
      expiresIn: payload.expires_in,
    })
  },
  async getUserInfo({ dispatch, state }) {
    const userDetails = await this.$api.user.search(state.username)
    this.$sentry.setUser({ username: state.username })
    dispatch('setUserData', userDetails[0])
    dispatch('setUserId', userDetails[0].id)
    dispatch(
      'userSettings/setPreferredLanguage',
      userDetails[0].preferredLanguage,
      { root: true }
    )
  },
  setTokens({ commit, dispatch }, { token, tokenType, refreshToken, expiresIn }) {
    this.$axios.setToken(token, tokenType)
    commit('SET_TOKEN', token)
    commit('SET_REFRESH_TOKEN', refreshToken)
    commit('SET_TOKEN_EXPIRES_IN', expiresIn)
    dispatch('getUserInfo')
  },
  logout({ commit, dispatch }) {
    this.$sentry.setUser(null);
    commit('RESET')
    dispatch('userSettings/reset', null, {
      root: true
    })
  },
  setUsername({ commit }, payload) {
    commit('SET_USERNAME', payload)
  },
  setUserId({ commit }, payload) {
    commit('SET_ID', payload)
  },
  setUserData({ commit }, payload) {
    commit('SET_USERDATA', payload)
  }
}
