const resource = 'residencenote'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  allFilteredByResidence(residenceId) {
    return $axios.$get(
      `/${resource}?$filter=residenceId eq ${residenceId}&$orderby=specifiedDateTime desc`
    )
  },
  totalNotesPerResidence(residenceId) {
    return $axios.get(`/${resource}/${residenceId}/count`)
  },
  allFilteredByResidenceWithPageAndTimeInterval(
    residenceId,
    page,
    nrOfItemsPerPage,
    searchWord,
    dateStart,
    dateEnd
  ) {
    return $axios.$get(
      `/${resource}?$filter=residenceId eq ${residenceId} and contains(description, '${searchWord}') and specifiedDateTime ge ${dateStart} and specifiedDateTime le ${dateEnd} &$skip=${page * nrOfItemsPerPage}&$top=${nrOfItemsPerPage}&$orderby=specifiedDateTime desc`
    )
  },
  allFilteredByResidenceWithSearch(
    residenceId,
    page,
    nrOfItemsPerPage,
    searchWord
  ) {
    return $axios.$get(
      `/${resource}?$filter=residenceId eq ${residenceId} and contains(description, '${searchWord}')&$skip=${page * nrOfItemsPerPage}&$top=${nrOfItemsPerPage}&$orderby=specifiedDateTime desc`
    )
  },
  show(noteId) {
    return $axios.$get(`/${resource}?$filter=id eq ${noteId}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  },
  allFilteredForDashboardResidence(residenceId, yesterday, today) {
    return $axios.$get(
      `/${resource}?$filter=residenceId eq ${residenceId} and specifiedDateTime ge ${yesterday} and  specifiedDateTime le ${today}&$orderby=specifiedDateTime desc`
    )
  }
})
