
  export default {
    name: "base-progress",
    props: {
      striped: {
        type: Boolean,
        description: "Whether progress is striped"
      },
      animated: {
        type: Boolean,
        description:
          "Whether progress is animated (works only with `striped` prop together)"
      },
      label: {
        type: String,
        description: "Progress label (shown on the left above progress)"
      },
      height: {
        type: Number,
        default: 3,
        description: "Progress line height"
      },
      type: {
        type: String,
        default: "default",
        description: "Progress type (e.g danger, primary etc)"
      },
      showLabel: {
        type: Boolean,
        default: false
      },
      progressClasses: {
        type: [Array, String],
        default: '',
        description: 'Progress css classes'
      },
      size: {
        type: String,
        default: ''
      },
      value: {
        type: Number,
        default: 0,
        validator: value => {
          return value >= 0 && value <= 100;
        },
        description: "Progress value"
      }
    },
    computed: {
      computedClasses() {
        return [
          { "progress-bar-striped": this.striped },
          { "progress-bar-animated": this.animated },
          { [`bg-${this.type}`]: this.type }
        ];
      }
    }
  };
