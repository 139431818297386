import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

export default (ctx, inject) => {
  function cleanUrlForLogging(url) {
    return url
      // .replace(/(?:(\$\w+)\=[^$&]+)/g, '$1=<redacted>')
      .replace(
        /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g,
        '<guid>'
      )
      .replace(/\d+/g, '<digits>')
  }

  Sentry.init({
    Vue,
    dsn: ctx.env.sentryDsn,
    environment: ctx.env.sentryEnv,
    release: ctx.env.sentryRelease,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', 'zorgwiel.nl', /^\//],
        beforeNavigate: context => {
          return {
            ...context,
            name: cleanUrlForLogging(location.pathname)
          }
        }
      })
    ],
    beforeSend(event) {
        if (event.location) {
            event.location = cleanUrlForLogging(event.location)
        }
        if (event.location) {
            event.transaction = cleanUrlForLogging(event.transaction)
        }
        if (event.errors) {
            for (const error of event.errors) {
                if (error.config?.isAxiosError) {
                    Sentry.withScope(scope => {
                        const cleanedUrl = cleanUrlForLogging(error.config.url)
                        scope.setFingerprint([error.config.method, cleanedUrl, error.message, error.stack])
                        scope.setTag("axios");
                    })
                } else {
                  scope.setFingerprint([error.message, error.stack])
                }
            }
        }
        return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
    attachStacktrace: true,
    autoSessionTracking: true,
    tracingOptions: {
      trackComponents: true,
      hooks: ['mount', 'update']
    }
  })

  inject('cleanUrlForLogging', cleanUrlForLogging)
  inject('sentry', Sentry)
}
