const mutations = {
  SET_DESCRIPTION(state, saveNote) {
    state.saveNote.description = saveNote
  },
  SET_RESIDENT_ID(state, saveNote) {
    state.saveNote.residentId = saveNote
  }
}

export default mutations
