const resource = 'user'

export default $axios => ({
  resetPassword(username) {
    return $axios.$post(`/${resource}/resetPassword`, { username })
  },
  all() {
    return $axios.$get(`/${resource}`)
  },
  allStaff() {
    return $axios.$get(
      `/${resource}?$filter=isStaff eq true&$orderby=lastName ASC`
    )
  },
  allExternal() {
    return $axios.$get(
      `/${resource}?$filter=isStaff eq false&$orderby=lastName ASC`
    )
  },
  allGeneral() {
    return $axios.$get(`/${resource}/general`)
  },
  allGeneralStaff() {
    return $axios.$get(`/${resource}/general?$filter=isStaff eq true`)
  },
  allGeneralExternal() {
    return $axios.$get(`/${resource}/general?$filter=isStaff eq false`)
  },
  show(id) {
    return $axios.$get(`/${resource}?$filter=id eq ${id}`)
  },
  search(username) {
    return $axios.$get(
      `/${resource}?$filter=toupper(userName) eq '${username.toUpperCase()}'`
    )
  },
  create(user) {
    return $axios.$post(`/${resource}`, user)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  enable(username) {
    return $axios.$get(`/${resource}/${username}/unblock`)
  },
  disable(username) {
    return $axios.$get(`/${resource}/${username}/block`)
  },
  delete(username) {
    return $axios.$delete(`/${resource}/${username}`)
  },
  activationEmail() {
    return $axios.$post(`/${resource}/currentUser/reconfirmEmail`, {
      EmailConfirmationSuccessCallbackUrl: `${process.env.hostUrl}/activation/successful`,
      EmailConfirmationFailCallbackUrl: `${process.env.hostUrl}/activation/failed`
    })
  }
})
