export default ctx => {
  if (ctx.store.state.auth.token) {
    ctx.$axios.setToken(ctx.store.state.auth.token, 'Bearer')
  }

  let isAlreadyFetchingAccessToken = false
  let subscribers = []

  function onAccessTokenFetched(token) {
    subscribers = subscribers.filter(callback => callback(token))
  }

  function addSubscriber(callback) {
    subscribers.push(callback)
  }

  ctx.$axios.interceptors.response.use(
    function(response) {
      return response
    },
    function(error) {
      const { config, response } = error
      const originalRequest = config

      if (response.status === 401) {
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true
          ctx.app.$api.connect
            .refreshToken(ctx.store.state.auth.refreshToken)
            .then(tokenRefreshResponse => {
              ctx.store.dispatch('auth/setTokens', {
                token: tokenRefreshResponse.access_token,
                tokenType: tokenRefreshResponse.token_type,
                refreshToken: tokenRefreshResponse.refresh_token
              })
              isAlreadyFetchingAccessToken = false
              onAccessTokenFetched(tokenRefreshResponse.access_token)
            })
            .catch(() => {
              ctx.store.dispatch('auth/logout')
              window.location.href = '/login'
              // ctx.router.push('/login')
            })
        }

        const retryOriginalRequest = new Promise(resolve => {
          addSubscriber(token => {
            originalRequest.headers.Authorization = 'Bearer ' + token
            resolve(ctx.$axios(originalRequest))
          })
        })
        return retryOriginalRequest
      } else if (response.status === 403) {
        if (response.data === 'This user is locked by the administrator') {
          window.location.href = '/locked'
        } else if (response.data === 'Email Confirmation Required') {
          window.location.href = '/activate'
        }
      }
      return Promise.reject(error)
    }
  )
}
