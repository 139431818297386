import connect from '~/api/connect.js'
import user from '~/api/user.js'
import resident from '~/api/resident.js'
import residence from '~/api/residence.js'
import documents from '~/api/documents.js'
import userrole from '~/api/userrole.js'
import role from '~/api/role.js'
import roleClaim from '~/api/roleClaim.js'
import userClaim from '~/api/userClaim.js'
import healthcareProvider from '~/api/healthcareProvider.js'
import healthcareProviderContract from '~/api/healthcareProviderContract.js'
import healthcareSpecialist from '~/api/healthcareSpecialist.js'
import healthcareSpecialistAssignment from '~/api/healthcareSpecialistAssignment.js'
import residentNote from '~/api/residentNote.js'
import residenceNote from '~/api/residenceNote.js'
import incident from '~/api/incident.js'
import goals from '~/api/goals.js'
import goalNotification from '~/api/goalNotification.js'
import medication from '~/api/medication.js'
import measurementTemplateInstance from '~/api/measurementTemplateInstance.js'
import measurementTemplate from '~/api/measurementTemplate.js'
import measurement from '~/api/measurement.js'
import measurementValueDefinition from '~/api/measurementValueDefinition.js'
import print from '~/api/print.js'
import riskAnalysis from '~/api/riskAnalysis.js'
import dayout from '~/api/dayout.js'
import sport from '~/api/sport.js'
import plan from '~/api/plan.js'
import volunteer from '~/api/volunteer.js'
import volunteerAssignment from '~/api/volunteerAssignment.js'
import goalEvent from '~/api/goalEvent.js'
import agenda from '~/api/agenda.js'
import agendaEvent from '~/api/agendaEvent.js'
import agreements from '~/api/agreements.js'
import contract from '~/api/contract.js'
import archive from "@/api/archive";

export default (ctx, inject) => {
  const api = {
    connect: connect(ctx.$axios),
    user: user(ctx.$axios),
    resident: resident(ctx.$axios),
    residence: residence(ctx.$axios),
    documents: documents(ctx.$axios),
    role: role(ctx.$axios),
    userrole: userrole(ctx.$axios),
    roleClaim: roleClaim(ctx.$axios),
    userClaim: userClaim(ctx.$axios),
    incident: incident(ctx.$axios),
    goals: goals(ctx.$axios),
    goalNotification: goalNotification(ctx.$axios),
    healthcareProvider: healthcareProvider(ctx.$axios),
    healthcareProviderContract: healthcareProviderContract(ctx.$axios),
    healthcareSpecialist: healthcareSpecialist(ctx.$axios),
    healthcareSpecialistAssignment: healthcareSpecialistAssignment(ctx.$axios),
    residentNote: residentNote(ctx.$axios),
    residenceNote: residenceNote(ctx.$axios),
    medication: medication(ctx.$axios),
    measurementTemplateInstance: measurementTemplateInstance(ctx.$axios),
    measurementTemplate: measurementTemplate(ctx.$axios),
    measurement: measurement(ctx.$axios),
    measurementValueDefinition: measurementValueDefinition(ctx.$axios),
    print: print(ctx.$axios),
    riskAnalysis: riskAnalysis(ctx.$axios),
    dayout: dayout(ctx.$axios),
    sport: sport(ctx.$axios),
    plan: plan(ctx.$axios),
    volunteer: volunteer(ctx.$axios),
    volunteerAssignment: volunteerAssignment(ctx.$axios),
    goalEvent: goalEvent(ctx.$axios),
    agenda: agenda(ctx.$axios),
    agendaEvent: agendaEvent(ctx.$axios),
    agreements: agreements(ctx.$axios),
    contract: contract(ctx.$axios),
    archive: archive(ctx.$axios)
  }

  inject('api', api)
}
