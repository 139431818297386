const resource = 'volunteer'

export default $axios => ({
  showAll() {
    return $axios.$get(`/${resource}`)
  },
  show(volunteerId) {
    return $axios.$get(`/${resource}?$filter=id eq ${volunteerId}`)
  },
  delete(volunteerId) {
    return $axios.$delete(`/${resource}/${volunteerId}`)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  }
})
