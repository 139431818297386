const resource = 'healthcareSpecialistAssignment'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  showByResident(id) {
    return $axios.$get(`/${resource}?$filter=residentId eq ${id}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  }
})
