const resource = 'incident'

export default $axios => ({
  getAllIncidentsByUserType(userType) {
    return $axios.$get(`/${resource}?$filter=userType eq '${userType}'`)
  },
  getCreatedBy(id) {
    return $axios.$get(`/${resource}?$filter=createdBy eq ${id}`)
  },
  getForSpecificResident(residentId) {
    return $axios.$get(`/${resource}?$filter=userId eq ${residentId}`)
  },
  getSpecificIncident(incidentId) {
    return $axios.$get(`/${resource}?$filter=id eq ${incidentId}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(incidentId, payload) {
    return $axios.$put(`/${resource}/${incidentId}`, payload)
  },
  delete(incidentId) {
    return $axios.$delete(`/${resource}/${incidentId}`)
  }
})
