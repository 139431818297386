const resource = 'resident'

export default $axios => ({
  showAll() {
    return $axios.$get(`/${resource}`)
  },
  show(id) {
    return $axios.$get(`/${resource}?$filter=id eq ${id}`)
  },
  addResidentToResidence(resident) {
    return $axios.$post(`/${resource}`, resident)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(residentId) {
    return $axios.delete(`/${resource}/${residentId}`)
  }
})
