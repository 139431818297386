const resource = 'residence'

export default $axios => ({
  getResidences() {
    return $axios.$get(`/${resource}?$orderby=name ASC`)
  },
  getResidence(residenceId) {
    return $axios.$get(`/${resource}?$filter=id eq ${residenceId}`)
  },
  getResidents(residenceId) {
    return $axios.$get(
      `/${resource}/${residenceId}/users?$filter=type eq 'Resident'&$orderby=lastName ASC`
    )
  },
  getResidentsActive(residenceId) {
    return $axios.$get(
      `/${resource}/${residenceId}/users?$filter=type eq 'Resident' and status eq 'Active'&$orderby=lastName ASC`
    )
  },
  getResidentsDeactivated(residenceId) {
    return $axios.$get(
      `/${resource}/${residenceId}/users?$filter=type eq 'Resident' and status eq 'Deactivated'&$orderby=lastName ASC`
    )
  },
  getResidentsNonActive(residenceId) {
    return $axios.$get(
      `/${resource}/${residenceId}/users?$filter=type eq 'Resident' and (status eq 'Deactivated' or status eq 'Deceased' or status eq 'Moved')&$orderby=lastName ASC`
    )
  },
  getActiveUsers(residenceId) {
    return $axios.$get(
      `/${resource}/${residenceId}/users?$filter=type eq 'User' and lockoutEnable eq false&$orderby=lastName ASC`
    )
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  delete(payload) {
    return $axios.$delete(`/${resource}/${payload}`)
  },
  update(residenceId, payload) {
    return $axios.put(`/${resource}/${residenceId}`, payload)
  }
})
