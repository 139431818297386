const resource = 'measurementtemplate'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  }
})
