const resource = 'volunteerAssignment'

export default $axios => ({
  showAll() {
    return $axios.$get(`/${resource}`)
  },
  show(residentId) {
    return $axios.$get(`/${resource}?$filter=residentId eq ${residentId}`)
  },
  delete(assignmentId) {
    return $axios.$delete(`/${resource}/${assignmentId}`)
  },
  update(id, payload) {
    return $axios.$put(`/${resource}/${id}`, payload)
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  }
})
