
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
      warningTimer: null,
      logoutTimer: null,
      showWarningMessage: false,
      previousLogOut: true
    }
  },
  mounted() {
    this.events.forEach(function(event) {
      window.addEventListener(
        event,
        () => {
          this.restartTimers()
        },
        false
      )
    }, this)

    this.setTimers()
  },
  destroyed() {
    this.stopListening()
  },
  methods: {
    ...mapActions('auth', ['logout']),

    setTimers() {
      this.warningTimer = setTimeout(
        () => this.displayWarningMessage(),
        process.env.autoLogoutWarningTimeout
      )
      this.logoutTimer = setTimeout(
        () => this.logUserOut(),
        process.env.autoLogoutTimeout
      )
    },
    displayWarningMessage() {
      this.showWarningMessage = true
    },
    logUserOut() {
      this.stopListening()
      this.logout()
      this.$router.push({
        name: 'login',
        params: { previousLogOut: this.previousLogOut }
      })
    },
    resetTimers() {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
    },
    restartTimers() {
      this.resetTimers()
      this.setTimers()
      this.showWarningMessage = false
    },
    stopListening() {
      this.events.forEach(function(event) {
        window.removeEventListener(event, this.restartTimers())
      }, this)

      this.resetTimers()
    }
  }
}
