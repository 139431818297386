// vee-validate language configuration
import nl from 'vee-validate/dist/locale/nl.json'
import en from 'vee-validate/dist/locale/en.json'
// element ui language configuration
import langEn from 'element-ui/lib/locale/lang/en'
import langNl from 'element-ui/lib/locale/lang/nl'
import locale from 'element-ui/lib/locale'

import { localize } from 'vee-validate'

import { Settings as LuxonSettings } from 'luxon'

export default function({ app }) {
  // Loading languages for Vee
  localize('en', en)
  localize('nl', nl)
  locale.use(langNl)
  // Localizing the app when user refresh or access a localized link
  localize(app.i18n.loadedLanguages[0])
  LuxonSettings.defaultLocale = 'nl-nl'
  // Called everytime on language change
  app.i18n.beforeLanguageSwitch = (oldLocale, newLocale) => {
    localize(newLocale)
    if (newLocale === 'en') {
      locale.use(langEn)
      LuxonSettings.defaultLocale = 'en-gb'
    } else if (newLocale === 'nl') {
      locale.use(langNl)
      LuxonSettings.defaultLocale = 'nl-nl'
    }
  }
}
