const resource = 'agenda'

export default $axios => ({
  getAll() {
    return $axios.$get(`/${resource}`)
  },
  getAgendaPerResidence(objectId) {
    return $axios.$get(`/${resource}?$filter=objectId eq ${objectId}`)
  },
  getAgendaItem(agendaId) {
    return $axios.$get(`/${resource}?$filter=id eq ${agendaId}`)
  },
  create(payload) {
    return $axios.post(`/${resource}`, payload)
  },
  update(agendaId, payload) {
    return $axios.put(`/${resource}/${agendaId}`, payload)
  },
  delete(agendaId) {
    return $axios.delete(`/${resource}/${agendaId}`)
  },
  customFilter(listOfAgends) {
    return $axios.$get(`/${resource}?$filter=${listOfAgends}`)
  }
})
