const resource = 'roleclaim'

export default $axios => ({
  all() {
    return $axios.$get(`/${resource}`)
  },
  show(id) {
    return $axios.$get(`/${resource}?$filter=roleId eq ${id}`)
  },
  create(role, claim, value) {
    const payload = {
      roleName: role,
      name: claim,
      value
    }
    return $axios.$post(`/${resource}`, payload)
  },
  update(role, claim, value) {
    const payload = {
      roleName: role,
      name: claim,
      value
    }
    return $axios.$put(`/${resource}`, payload)
  },
  delete(role, claim, value) {
    const payload = {
      roleName: role,
      name: claim,
      value
    }
    return $axios({
      url: `/${resource}`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: payload
    })
  },
  currentClaims() {
    return $axios.$get(`/${resource}/currentClaims`)
  },
  currentUserIsGeneral() {
    return $axios.$get(`/${resource}/currentUserIsGeneral`)
  }
})
