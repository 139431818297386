const resource = 'measurementTemplateInstance'

export default $axios => ({
  getFromResident(id) {
    return $axios.$get(`/${resource}/?$filter=residentId eq ${id}`)
  },
  getInstance(id) {
    return $axios.$get(`/${resource}/?$filter=id eq ${id}`).then(x => x[0])
  },
  create(payload) {
    return $axios.$post(`/${resource}`, payload)
  },
  delete(id) {
    return $axios.$delete(`/${resource}/${id}`)
  }
})
